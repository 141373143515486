/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  defaultDateSelection,
  granularityDropdownItems
} from 'app/components/filters/constants';
import {
  filterParamsSelector
  //  ssidWLANListSelector
} from 'app/redux/filters';
import { dateRangeFilterTransform } from 'app/redux/filters/transforms';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchPropertyMqttClientBandwidthTrendComplete,
  fetchPropertyMqttClientBandwidthTrendError,
  fetchPropertyMqttClientDetailsComplete,
  fetchPropertyMqttClientDetailsError,
  updateMqttClientBandwidthTrendFetchState,
  updatePropertyMqttClientDetailsFetchState
} from '.';

export function* fetchMqttClientDetailsSaga({ payload }) {
  yield put(updatePropertyMqttClientDetailsFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const params = yield merge(
    {},
    filters,
    payload,
    { zoneName: get(payload, 'property.name', selectedPropertyName) },
    { wlanName: get(payload, 'wlanName', 'all') }
  );

  const url = '/api/fastapi/getListOfMQTTClientDetails';
  const paramNames = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName',
    'zoneName'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyMqttClientDetailsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyMqttClientDetailsComplete(data));
    yield put(updatePropertyMqttClientDetailsFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyMqttClientDetailsError, e));
    yield put(updatePropertyMqttClientDetailsFetchState(failedFetchState));
  }
}

export function* fetchMqttClientBandwidthTrendSaga({ payload }) {
  yield put(updateMqttClientBandwidthTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const { timeUnit, timeUnitFrom, timeUnitTo } = yield dateRangeFilterTransform(
    get(payload, 'dateRange', defaultDateSelection)
  );
  
  const wlanName = yield get(payload, 'wlanName', 'all');
  // commmenting ssid bcz this param not required in the url payload
  // const ssidWLANList = yield select(ssidWLANListSelector);
  // const ssid = yield get(find(ssidWLANList, ['wlan', wlanName]), 'ssid', 'all');
  const params = yield merge(
    {},
    filters,
    payload,
    { zoneName: get(payload, 'property.name', selectedPropertyName) },
    { wlanName },
    // { ssid },
    { clientMac: get(payload, 'clientMAC', '') },
    { timeUnit, timeUnitFrom, timeUnitTo },
    {
      granularity: get(
        payload,
        'granularity',
        granularityDropdownItems._15MinutesGranularity.value
      )
    }
  );

  const url = '/api/fastapi/getMQTTClientBandwidthTrend';
  const paramNames = [
    'clientMac',
    'granularity',
    // 'ssid', commmenting this bcz ssid param not required in the url payload
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName',
    'zoneName'
  ];

  try {
    const onTimeout = () =>
      put(updateMqttClientBandwidthTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyMqttClientBandwidthTrendComplete(data));
    yield put(updateMqttClientBandwidthTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyMqttClientBandwidthTrendError, e));
    yield put(updateMqttClientBandwidthTrendFetchState(failedFetchState));
  }
}

export default function createFetchPropertyMqttClientsSagas(types) {
  return [
    takeLatest(
      types.fetchPropertyMqttClientDetails,
      fetchMqttClientDetailsSaga
    ),
    takeLatest(
      types.fetchPropertyMqttClientBandwidthTrend,
      fetchMqttClientBandwidthTrendSaga
    )
  ];
}
