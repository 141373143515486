/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  dateRangeFilterSelector,
  filterParamsSelector
  // ssidWLANListSelector
} from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet, debounceSaga } from 'app/redux/util-sagas';
import { genericAjaxPostSaga } from 'app/redux/util-sagas/generic-ajax-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getGranularityForDateRange, getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  apStatusTrendSelector,
  fetchAPStatusTrendComplete,
  fetchAPStatusTrendError,
  fetchPropertyAPUniqueClientTrendComplete,
  fetchPropertyAPUniqueClientTrendError,
  fetchPropertyClientStatusComplete,
  fetchPropertyClientStatusError,
  fetchPropertyUniqueClientsTrendComplete,
  fetchPropertyUniqueClientsTrendError,
  propertyUniqueClientsTrendSelector,
  updateAPStatusTrendFetchState,
  updatePropertyAPUniqueClientTrendFetchState,
  updatePropertyClientStatusFetchState,
  updatePropertyUniqueClientsTrendFetchState
} from './';

export function* fetchPropertyClientStatusSaga({ payload }) {
  yield put(updatePropertyClientStatusFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    apMAC: get(payload, 'apMAC', 'all'),
    wlanName: get(payload, 'wlanName', 'all')
  });

  const url = '/api/fastapi/getPropertyClientByAPTrend';
  const paramNames = [
    'apMAC',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyClientStatusFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyClientStatusComplete(data));
    yield put(updatePropertyClientStatusFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyClientStatusError, e));
    yield put(updatePropertyClientStatusFetchState(failedFetchState));
  }
}

export function* fetchPropertyUniqueClientsTrendSaga({ payload }) {
  yield put(updatePropertyUniqueClientsTrendFetchState(pendingFetchState));

  const currentDataSet = yield select(propertyUniqueClientsTrendSelector);
  yield call(debounceSaga, currentDataSet);

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const dateRangeFilter = select(dateRangeFilterSelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const wlanName = yield get(payload, 'wlanName', 'all');

  // commmenting ssid bcz this param not required in the url payload
  // const ssidWLANList = yield select(ssidWLANListSelector);
  // const ssid = yield get(find(ssidWLANList, ['wlan', wlanName]), 'ssid', 'all');
  const granularity = yield getGranularityForDateRange(dateRangeFilter);

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    apMAC: get(payload, 'apMAC', 'all'),
    wlanName,
    // ssid,
    granularity: get(payload, 'granularity', granularity)
  });

  const url = '/api/fastapi/getPropertyUniqueClientsTrend';
  const paramNames = [
    'apMAC',
    'granularity',
    // 'ssid',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyUniqueClientsTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyUniqueClientsTrendComplete(data));
    yield put(updatePropertyUniqueClientsTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyUniqueClientsTrendError, e));
    yield put(updatePropertyUniqueClientsTrendFetchState(failedFetchState));
  }
}

export function* fetchAPStatusTrendSaga({ payload }) {
  const url = '/api/device/getAPStatusTrend';
  const timeFrequency = get(payload, 'timeFrequency', 'hourly');

  const sagaOptions = {
    completeAction: fetchAPStatusTrendComplete,
    errorAction: fetchAPStatusTrendError,
    currentDataSelector: apStatusTrendSelector,
    updateFetchState: updateAPStatusTrendFetchState
  };

  yield call(genericAjaxPostSaga, [url], sagaOptions, {
    additionalParams: { timeFrequency }
  });
}

export function* fetchPropertyAPUniqueClientTrendSaga({ payload }) {
  yield put(updatePropertyAPUniqueClientTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    apMAC: get(payload, 'apMAC', 'all'),
    wlanName: get(payload, 'wlanName', 'all')
  });

  const url = '/api/fastapi/getAPUniqueClientTrend';
  const paramNames = [
    'apMAC',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyAPUniqueClientTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyAPUniqueClientTrendComplete(data));
    yield put(updatePropertyAPUniqueClientTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyAPUniqueClientTrendError, e));
    yield put(updatePropertyAPUniqueClientTrendFetchState(failedFetchState));
  }
}

export default function createFetchPropertyClientStatusSagas(types) {
  return [
    takeLatest(types.fetchPropertyClientStatus, fetchPropertyClientStatusSaga),
    takeLatest(
      types.fetchPropertyUniqueClientsTrend,
      fetchPropertyUniqueClientsTrendSaga
    ),
    takeLatest(types.fetchAPStatusTrend, fetchAPStatusTrendSaga),
    takeLatest(
      types.fetchPropertyAPUniqueClientTrend,
      fetchPropertyAPUniqueClientTrendSaga
    )
  ];
}
