/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  dateRangeFilterSelector,
  filterParamsSelector
  // ssidWLANListSelector
} from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getGranularityForDateRange, getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchPropertyUniqueUsersTrendComplete,
  fetchPropertyUniqueUsersTrendError,
  fetchPropertyUserBandwidthTrendComplete,
  fetchPropertyUserBandwidthTrendError,
  fetchPropertyUserDataUsageComplete,
  fetchPropertyUserDataUsageError,
  fetchPropertyUsersComplete,
  fetchPropertyUsersError,
  updatePropertyUniqueUsersTrendFetchState,
  updatePropertyUserBandwidthTrendFetchState,
  updatePropertyUserDataUsageFetchState,
  updatePropertyUsersFetchState
} from './';

export function* fetchPropertyUsersSaga({ payload }) {
  yield put(updatePropertyUsersFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName: get(payload, 'wlanName', 'all')
    // topUsers: 20
  });

  const url = '/api/fastapi/getListOfALEUsers';
  const queryParams = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    // 'topUsers',
    'vertical',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyUsersFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield queryParams
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyUsersComplete(data));
    yield put(updatePropertyUsersFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyUsersError, e));
    yield put(updatePropertyUsersFetchState(failedFetchState));
  }
}

export function* fetchPropertyUserBandwidthTrendSaga({ payload }) {
  yield put(updatePropertyUserBandwidthTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName: get(payload, 'wlanName', 'all'),
    userName: get(payload, 'userName', '')
  });

  const url = '/api/fastapi/getSPUserBandwidthTrend';
  const queryParams = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'userName',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyUserBandwidthTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield queryParams
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyUserBandwidthTrendComplete(data));
    yield put(updatePropertyUserBandwidthTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyUserBandwidthTrendError, e));
    yield put(updatePropertyUserBandwidthTrendFetchState(failedFetchState));
  }
}

export function* fetchPropertyUniqueUsersTrendSaga({ payload }) {
  yield put(updatePropertyUniqueUsersTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const dateRangeFilter = select(dateRangeFilterSelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const wlanName = yield get(payload, 'wlanName', 'all');
  // commmenting ssid bcz this param not required in the url payload
  // const ssidWLANList = yield select(ssidWLANListSelector);
  // const ssid =
  //   ssidWLANList[0] &&
  //   ssidWLANList[0]['ssid'] !== undefined &&
  //   ssidWLANList[0]['ssid'] !== ''
  //     ? yield get(
  //       find(ssidWLANList, ['wlan', wlanName]),
  //       'ssid',
  //       ssidWLANList[0]['ssid']
  //     )
  //     : 'all';

  const granularity = yield getGranularityForDateRange(dateRangeFilter);
  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    apMAC: get(payload, 'apMAC', 'all'),
    wlanName,
    // ssid,
    granularity: get(payload, 'granularity', granularity)
  });

  const url = '/api/fastapi/getPropertyUniqueUsersTrend';
  const paramNames = [
    'apMAC',
    'granularity',
    // 'ssid',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyUniqueUsersTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyUniqueUsersTrendComplete(data));
    yield put(updatePropertyUniqueUsersTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyUniqueUsersTrendError, e));
    yield put(updatePropertyUniqueUsersTrendFetchState(failedFetchState));
  }
}

export function* fetchPropertyUserDataUsageSaga({ payload }) {
  yield put(updatePropertyUserDataUsageFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName: get(payload, 'wlanName', 'all'),
    userName: get(payload, 'userName', ''),
    includeSSID: get(payload, 'includeSSID', true)
  });

  const url = '/api/fastapi/getSPUserDataUsage';
  const queryParams = [
    'includeSSID',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'userName',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyUserDataUsageFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield queryParams
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyUserDataUsageComplete(data));
    yield put(updatePropertyUserDataUsageFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyUserDataUsageError, e));
    yield put(updatePropertyUserDataUsageFetchState(failedFetchState));
  }
}

export default function createFetchPropertyUsersSagas(types) {
  return [
    takeLatest(types.fetchPropertyUsers, fetchPropertyUsersSaga),
    takeLatest(
      types.fetchPropertyUserBandwidthTrend,
      fetchPropertyUserBandwidthTrendSaga
    ),
    takeLatest(
      types.fetchPropertyUniqueUsersTrend,
      fetchPropertyUniqueUsersTrendSaga
    ),
    takeLatest(types.fetchPropertyUserDataUsage, fetchPropertyUserDataUsageSaga)
  ];
}
