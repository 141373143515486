/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateSelection } from 'app/components/filters/constants';
import {
  filterParamsSelector
  // ssidWLANListSelector
} from 'app/redux/filters';
import { dateRangeFilterTransform } from 'app/redux/filters/transforms';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent, getCustomers } from 'app/utils';
import { get, merge } from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchClientListComplete,
  fetchClientListError,
  fetchPropertyClientBandwidthTrendComplete,
  fetchPropertyClientBandwidthTrendError,
  fetchPropertyClientsComplete,
  fetchPropertyClientsError,
  fetchUserListComplete,
  fetchUserListError,
  updateClientListFetchState,
  updatePropertyClientBandwidthTrendFetchState,
  updatePropertyClientsFetchState,
  updateUserListFetchState
} from './';

export function* fetchPropertyClientsSaga({ payload }) {
  yield put(updatePropertyClientsFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName: get(payload, 'wlanName', 'all')
  });

  const url = '/api/fastapi/getListOfClientDetails';
  const queryParams = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updatePropertyClientsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield queryParams
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyClientsComplete(data));
    yield put(updatePropertyClientsFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyClientsError, e));
    yield put(updatePropertyClientsFetchState(failedFetchState));
  }
}

export function* fetchPropertyClientBandwidthTrendSaga({ payload }) {
  yield put(updatePropertyClientBandwidthTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const { timeUnit, timeUnitFrom, timeUnitTo } = yield dateRangeFilterTransform(
    get(payload, 'dateRange', defaultDateSelection)
  );
  const wlanName = yield get(payload, 'wlanName', 'all');

  // commmenting ssid bcz this param not required in the url payload
  // const ssidWLANList = yield select(ssidWLANListSelector);
  // const ssid = yield get(find(ssidWLANList, ['wlan', wlanName]), 'ssid', 'all');

  // const fromDateSelection = day => {
  //   let fromDate = day;
  //   if (fromDate === '24Hours') {
  //     fromDate = 1;
  //   } else if (fromDate === 'week') {
  //     fromDate = 7;
  //   } else if (fromDate === 'month') {
  //     return moment()
  //       .startOf('month')
  //       .format('YYYY-MM-DD');
  //   } else if (fromDate === 'LastMonth') {
  //     return moment()
  //       .subtract(1, 'month')
  //       .startOf('month')
  //       .format('YYYY-MM-DD');
  //   } else {
  //     fromDate = 1;
  //   }
  //   const dateFrom = moment()
  //     .subtract(fromDate, 'd')
  //     .format('YYYY-MM-DD');
  //   return dateFrom;
  // };

  // const toDateSelection = day => {
  //   let toDate = day;
  //   if (toDate === '24Hours') {
  //     toDate = 0;
  //   } else if (toDate === 'month') {
  //     return moment()
  //       .subtract(1, 'd')
  //       .format('YYYY-MM-DD');
  //   } else if (toDate === 'LastMonth') {
  //     return moment()
  //       .subtract(1, 'month')
  //       .endOf('month')
  //       .format('YYYY-MM-DD');
  //   } else {
  //     toDate = 1;
  //   }
  //   const dateFrom = moment()
  //     .subtract(toDate, 'd')
  //     .format('YYYY-MM-DD');
  //   return dateFrom;
  // };

  // const updatedTimeUnit = timeUnit => {
  //   if (timeUnit === '24Hours') {
  //     return '24Hours';
  //   } else if (timeUnit === 'week') {
  //     return 'week';
  //   } else if (timeUnit === 'month') {
  //     return 'month';
  //   } else if (timeUnit === 'LastMonth') {
  //     return 'month';
  //   } else {
  //     return '';
  //   }
  // };

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName,
    // ssid,
    clientMAC: get(payload, 'clientMAC', ''),
    timeUnit,
    timeUnitFrom,
    timeUnitTo
  });

  const url = '/api/fastapi/getSPClientBandwidthTrend';
  const queryParams = [
    'clientMAC',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    // 'ssid',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(
        updatePropertyClientBandwidthTrendFetchState(timeoutNotifyFetchState)
      );

    const queryStringParams = yield queryParams
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyClientBandwidthTrendComplete(data));
    yield put(updatePropertyClientBandwidthTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyClientBandwidthTrendError, e));
    yield put(updatePropertyClientBandwidthTrendFetchState(failedFetchState));
  }
}

export function* fetchPropertyResidentClientListSaga({ payload }) {
  yield put(updateClientListFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const seletedOperator = yield getCustomers(hierarchy, selectedPath);
  const seletedOperatorName = seletedOperator[0].value;

  let newOperatorName;
  if (seletedOperatorName === 'ARRIS' || seletedOperatorName === 'Arris') {
    newOperatorName = 'Altice';
  } else {
    newOperatorName = seletedOperatorName;
  }
  const selected = yield select(selectedPathSelector);
  const isCustomerLevel = yield isSpecificNodeType(selected, 'customer');
  const zoneValue = get(payload, 'property.name', selectedPropertyName);
  const zone = zoneValue ? zoneValue : 'all';

  const params = yield merge({}, filters, payload, {
    zone,
    type: get(payload, 'type', 'all'),
    operator: newOperatorName,
    vertical: 'MDU',
    mduType: isCustomerLevel ? get(payload, 'mduType', 'all') : undefined
  });

  if (!isCustomerLevel) {
    delete params.mduType;
  }
  const url = '/api/cdbapi/getDPSKDeviceList';
  const queryParams = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'operator',
    'type',
    'mduType',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updateClientListFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield queryParams
      .filter(p => params[p] !== undefined && params[p] !== null) // param is only included if it is defined
      .map(p => `${p}=${encodeURIComponent(params[p])}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchClientListComplete(data));
    yield put(updateClientListFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchClientListError, e));
    yield put(updateClientListFetchState(failedFetchState));
  }
}

export function* fetchPropertyUserListSaga({ payload }) {
  yield put(updateUserListFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');

  const seletedOperator = yield getCustomers(hierarchy, selectedPath);
  const seletedOperatorName = seletedOperator[0].value;

  let newOperatorName;
  if (seletedOperatorName === 'ARRIS' || seletedOperatorName === 'Arris') {
    newOperatorName = 'Altice';
  } else {
    newOperatorName = seletedOperatorName;
  }

  const selected = yield select(selectedPathSelector);
  const isCustomerLevel = yield isSpecificNodeType(selected, 'customer');
  const zoneValue = get(payload, 'property.name', selectedPropertyName);
  const zone = zoneValue ? zoneValue : 'all';

  const params = yield merge({}, filters, payload, {
    zone,
    lastAccessDays: get(payload, 'lastAccessDays', '30'),
    operator: newOperatorName,
    vertical: 'MDU',
    mduType: isCustomerLevel ? get(payload, 'mduType', 'all') : undefined
  });

  if (!isCustomerLevel) {
    delete params.mduType;
  }
  const url = '/api/cdbapi/getALEUserReport';
  const queryParams = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'operator',
    'lastAccessDays',
    'mduType',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updateUserListFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield queryParams
      .filter(p => params[p] !== undefined && params[p] !== null) // param is only included if it is defined
      .map(p => `${p}=${encodeURIComponent(params[p])}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchUserListComplete(data));
    yield put(updateUserListFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchUserListError, e));
    yield put(updateUserListFetchState(failedFetchState));
  }
}

export default function createFetchPropertyClientsSagas(types) {
  return [
    takeLatest(types.fetchPropertyClients, fetchPropertyClientsSaga),
    takeLatest(
      types.fetchPropertyClientBandwidthTrend,
      fetchPropertyClientBandwidthTrendSaga
    ),
    takeLatest(types.fetchClientList, fetchPropertyResidentClientListSaga),
    takeLatest(types.fetchUserList, fetchPropertyUserListSaga)
  ];
}
